@use "variables" as *;

.container {
  @include top-padding-with-header;
}

// Prevent double tap zoom on all buttons
// https://stackoverflow.com/questions/10614481/disable-double-tap-zoom-option-in-browser-on-touch-devices/53236027#53236027
button,
table[class^="hcr-clndr"] td {
  touch-action: manipulation;
}
