import {
  IconArrowDown,
  IconPauseSolid,
  IconSeatSolid,
  IconPlaySolid,
  Icon,
} from "@flixbus/honeycomb-icons-react";
import { Button, Dropdown, DropdownItem } from "@flixbus/honeycomb-react";
import * as React from "react";
import { useIntl } from "react-intl";
import { FindRidesForRideActionsQuery } from "../../api/operations.generated";
import BatchAdjustCapacityPopup from "../../containers/batchAdjustCapacityPopup/BatchAdjustCapacityPopup";
import BatchRestartSalesPopup from "../../containers/batchRestartSalesPopup/BatchRestartSalesPopup";
import BatchStopSalesPopup from "../../containers/batchStopSalesPopup/BatchStopSalesPopup";
import * as css from "./BatchOperationsDropdown.scss";

enum BatchOperationsPopups {
  CHANGE_CAPACITY,
  STOP_SALES,
  RESTART_SALES,
}

type Props = {
  selectedRides: FindRidesForRideActionsQuery["findRidesByDeparture"];
};

const BatchOperationsDropdown: React.FC<Props> = ({ selectedRides }) => {
  const [activePopup, setActivePopup] = React.useState<BatchOperationsPopups>();
  const { formatMessage } = useIntl();

  const INCREASE_ALLOWED = selectedRides.some(
    (ride) => ride.capacity.increaseNotPossibleReasons.length === 0
  );

  const DECREASE_ALLOWED = selectedRides.some(
    (ride) => ride.capacity.decreaseNotPossibleReasons.length === 0
  );

  const SALES_STOP_ALLOWED = selectedRides.some(
    (ride) => ride.salesStopNotPossibleReasons.length === 0
  );

  const SALES_RESTART_ALLOWED = selectedRides.some(
    (ride) => ride.salesRestartNotPossibleReasons.length === 0
  );

  const links = [
    <DropdownItem
      key="change-seats-on-offer"
      InlineIcon={IconSeatSolid}
      Elem="button"
      disabled={!INCREASE_ALLOWED && !DECREASE_ALLOWED}
      onClick={() => setActivePopup(BatchOperationsPopups.CHANGE_CAPACITY)}
    >
      {formatMessage({ id: "ride.batchUpdate.dropdown.item.adjustSeatsOnOffer" })}
    </DropdownItem>,
    <DropdownItem
      key="stop-sales"
      InlineIcon={IconPauseSolid}
      Elem="button"
      disabled={!SALES_STOP_ALLOWED}
      onClick={() => setActivePopup(BatchOperationsPopups.STOP_SALES)}
    >
      {formatMessage({ id: "ride.stopSales.button" })}
    </DropdownItem>,
    <DropdownItem
      key="restart-sales"
      InlineIcon={IconPlaySolid}
      Elem="button"
      disabled={!SALES_RESTART_ALLOWED}
      onClick={() => setActivePopup(BatchOperationsPopups.RESTART_SALES)}
    >
      {formatMessage({ id: "ride.restartSale.button" })}
    </DropdownItem>,
  ];

  return (
    <>
      <Dropdown extraClasses={css.dropdown} links={links}>
        <Button appearance="link">
          {formatMessage({ id: "ride.batchUpdate.dropdown.title" })}
          <Icon InlineIcon={IconArrowDown} />
        </Button>
      </Dropdown>
      {activePopup === BatchOperationsPopups.CHANGE_CAPACITY && (
        <BatchAdjustCapacityPopup
          selectedRides={selectedRides}
          onClose={() => setActivePopup(undefined)}
        />
      )}
      {activePopup === BatchOperationsPopups.STOP_SALES && (
        <BatchStopSalesPopup
          selectedRides={selectedRides}
          onClose={() => setActivePopup(undefined)}
        />
      )}
      {activePopup === BatchOperationsPopups.RESTART_SALES && (
        <BatchRestartSalesPopup
          selectedRides={selectedRides}
          onClose={() => setActivePopup(undefined)}
        />
      )}
    </>
  );
};

export default BatchOperationsDropdown;
