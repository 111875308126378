import { addNotification, NotificationType } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { useIntl } from "react-intl";
import ConfirmationPopup from "../../../../shared/components/confirmationPopup/ConfirmationPopup";
import {
  addRideUpdateErrors,
  updateRideUpdateErrors,
} from "../../../../state/rideUpdateErrors/handleRideUpdateErrors";
import { RideUpdate } from "../../../../state/rideUpdateErrors/types";
import {
  FindRidesForRideActionsQuery,
  useUpdateSeatsOnOfferMutation,
} from "../../api/operations.generated";
import getRidesWithFailedUpdate from "../../helpers/getRidesWithFailedUpdate/getRidesWithFailedUpdate";
import { includeAndExcludeRidesForCapacityChange } from "../../helpers/includeAndExcludeRidesForBatchOperations/includeAndExcludeRidesForBatchOperations";
import BatchAdjustExcludedRides from "../../ui/batchAdjustExcludedRides/BatchAdjustExcludedRides";
import CapacityInput from "../../ui/capacityInput/CapacityInput";

export type BatchAdjustCapacityPopupProps = {
  selectedRides: FindRidesForRideActionsQuery["findRidesByDeparture"];
  onClose: () => void;
};

const BatchAdjustCapacityPopup: React.FC<BatchAdjustCapacityPopupProps> = ({
  selectedRides,
  onClose,
}) => {
  const { formatMessage } = useIntl();

  const CAPACITY_ON_OFFER = Math.max(
    ...selectedRides.map((ride) => ride.capacity.seatsOnOfferExpected),
    0
  );

  const [capacity, setCapacity] = React.useState(CAPACITY_ON_OFFER);

  const [includedRides, excludedRides] = includeAndExcludeRidesForCapacityChange(
    selectedRides,
    capacity
  );

  const [updateSeatsOnOffer, { loading }] = useUpdateSeatsOnOfferMutation({
    onCompleted: (data) => {
      onClose();

      const ridesSuccess = data.adjustSeatsOnOffer.map((ride) => ride.id);
      const ridesFail = getRidesWithFailedUpdate(includedRides, ridesSuccess);

      updateRideUpdateErrors(ridesSuccess, ridesFail, RideUpdate.UpdateSeats);

      if (ridesSuccess.length) {
        addNotification({
          message: formatMessage(
            { id: "ride.batchUpdate.notification.seatsOnOffer.success" },
            {
              countRides: data.adjustSeatsOnOffer.length,
            }
          ),
          type: NotificationType.Success,
        });
      }

      if (ridesFail.length) {
        addNotification({
          message: formatMessage(
            { id: "ride.batchUpdate.notification.seatsOnOffer.fail" },
            {
              countRides: ridesFail.length,
            }
          ),
          type: NotificationType.Danger,
          isPermanent: true,
        });
      }
    },
    onError: () => {
      onClose();
      addRideUpdateErrors(includedRides, RideUpdate.UpdateSeats);
      addNotification({
        message: formatMessage(
          { id: "ride.batchUpdate.notification.seatsOnOffer.fail" },
          {
            countRides: includedRides.length,
          }
        ),
        type: NotificationType.Danger,
        isPermanent: true,
      });
    },
  });

  React.useEffect(() => {
    setCapacity(CAPACITY_ON_OFFER);
  }, [selectedRides]);

  const handleCapacityChange = () => {
    updateSeatsOnOffer({
      variables: {
        rides: includedRides,
        expectedSeatsOnOffer: capacity,
      },
    });
  };

  return (
    <ConfirmationPopup
      active
      titleText={formatMessage(
        { id: "ride.batchUpdate.popup.seatsOnOffer.title" },
        {
          countRides: includedRides.length,
        }
      )}
      confirmButtonText={formatMessage(
        { id: "ride.batchUpdate.popup.button.apply" },
        {
          countRides: includedRides.length,
        }
      )}
      confirmButtonDisabled={includedRides.length === 0}
      onConfirm={() => {
        handleCapacityChange();
      }}
      onCancel={() => onClose()}
      submitLoading={loading}
      isWide={false}
    >
      <CapacityInput
        capacityOnOffer={capacity}
        onCapacityChanged={(newCapacity) => setCapacity(newCapacity)}
      />
      {excludedRides.length > 0 && (
        <BatchAdjustExcludedRides rides={excludedRides} type="capacityChange" />
      )}
    </ConfirmationPopup>
  );
};

export default BatchAdjustCapacityPopup;
