// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// This file was automatically generated using npm run codegen
// If you want to alter this file please do so via the above mentioned command
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
import * as Types from "../../../shared/types/schema";

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
const defaultOptions = {} as const;
export type RouteEntryFragment = {
  __typename?: "RouteEntry";
  station: { __typename?: "Station"; name: string; code: string };
  time: {
    __typename?: "StationTime";
    localDeparture?: string | null;
    localArrival?: string | null;
  };
};

export type CapacityWithoutSegmentsFragment = {
  __typename?: "Capacity";
  decreaseNotPossibleReasons: Array<Types.CapacityManagementNotPossibleReasons>;
  increaseNotPossibleReasons: Array<Types.CapacityManagementNotPossibleReasons>;
  isCapacityInitiated: boolean;
  seatsOnOfferExpected: number;
  minSeatsOnOffer: number;
};

export type CapacityFragment = {
  __typename?: "Capacity";
  decreaseNotPossibleReasons: Array<Types.CapacityManagementNotPossibleReasons>;
  increaseNotPossibleReasons: Array<Types.CapacityManagementNotPossibleReasons>;
  isCapacityInitiated: boolean;
  seatsOnOfferExpected: number;
  minSeatsOnOffer: number;
  segmentCapacity: Array<{
    __typename?: "SegmentCapacityEntry";
    placeAvailable: number;
    placeBooked: number;
    placeReserved: number;
  }>;
};

export type FindRidesQueryVariables = Types.Exact<{
  start: Types.Scalars["String"]["input"];
  end: Types.Scalars["String"]["input"];
  filter?: Types.InputMaybe<Types.Filter>;
  contractPartnerId: Types.Scalars["ID"]["input"];
}>;

export type FindRidesQuery = {
  __typename?: "Query";
  findRidesByDeparture: Array<{
    __typename?: "Ride";
    id: string;
    status: string;
    salesStopNotPossibleReasons: Array<Types.CapacityManagementNotPossibleReasons>;
    salesRestartNotPossibleReasons: Array<Types.CapacityManagementNotPossibleReasons>;
    rideCancellationAllowed: boolean;
    lastModified?: string | null;
    lastModifiedUsername: string;
    cancellationStatus: Types.CancellationStatus;
    cancellationDate?: string | null;
    saleStatus: Types.RideSaleStatus;
    numberOfPassengers: number;
    line: { __typename?: "Line"; code: string };
    capacity: {
      __typename?: "Capacity";
      decreaseNotPossibleReasons: Array<Types.CapacityManagementNotPossibleReasons>;
      increaseNotPossibleReasons: Array<Types.CapacityManagementNotPossibleReasons>;
      isCapacityInitiated: boolean;
      seatsOnOfferExpected: number;
      minSeatsOnOffer: number;
    };
    route: Array<{
      __typename?: "RouteEntry";
      station: { __typename?: "Station"; name: string; code: string };
      time: {
        __typename?: "StationTime";
        localDeparture?: string | null;
        localArrival?: string | null;
      };
    }>;
  }>;
};

export type FindRidesForRideActionsQueryVariables = Types.Exact<{
  start: Types.Scalars["String"]["input"];
  end: Types.Scalars["String"]["input"];
  filter?: Types.InputMaybe<Types.Filter>;
  contractPartnerId: Types.Scalars["ID"]["input"];
}>;

export type FindRidesForRideActionsQuery = {
  __typename?: "Query";
  findRidesByDeparture: Array<{
    __typename?: "Ride";
    id: string;
    salesStopNotPossibleReasons: Array<Types.CapacityManagementNotPossibleReasons>;
    salesRestartNotPossibleReasons: Array<Types.CapacityManagementNotPossibleReasons>;
    capacity: {
      __typename?: "Capacity";
      decreaseNotPossibleReasons: Array<Types.CapacityManagementNotPossibleReasons>;
      increaseNotPossibleReasons: Array<Types.CapacityManagementNotPossibleReasons>;
      isCapacityInitiated: boolean;
      seatsOnOfferExpected: number;
      minSeatsOnOffer: number;
    };
    route: Array<{
      __typename?: "RouteEntry";
      station: { __typename?: "Station"; name: string; code: string };
      time: {
        __typename?: "StationTime";
        localDeparture?: string | null;
        localArrival?: string | null;
      };
    }>;
  }>;
};

export type FindRidesForRideTableHeaderQueryVariables = Types.Exact<{
  start: Types.Scalars["String"]["input"];
  end: Types.Scalars["String"]["input"];
  filter?: Types.InputMaybe<Types.Filter>;
  contractPartnerId: Types.Scalars["ID"]["input"];
}>;

export type FindRidesForRideTableHeaderQuery = {
  __typename?: "Query";
  findRidesByDeparture: Array<{ __typename?: "Ride"; id: string }>;
};

export type LoadCapacityQueryVariables = Types.Exact<{
  id: Types.Scalars["ID"]["input"];
}>;

export type LoadCapacityQuery = {
  __typename?: "Query";
  getRide: {
    __typename?: "Ride";
    id: string;
    capacity: {
      __typename?: "Capacity";
      decreaseNotPossibleReasons: Array<Types.CapacityManagementNotPossibleReasons>;
      increaseNotPossibleReasons: Array<Types.CapacityManagementNotPossibleReasons>;
      isCapacityInitiated: boolean;
      seatsOnOfferExpected: number;
      minSeatsOnOffer: number;
      segmentCapacity: Array<{
        __typename?: "SegmentCapacityEntry";
        placeAvailable: number;
        placeBooked: number;
        placeReserved: number;
      }>;
    };
  };
};

export type UpdateSeatsOnOfferMutationVariables = Types.Exact<{
  rides: Array<Types.Scalars["ID"]["input"]> | Types.Scalars["ID"]["input"];
  expectedSeatsOnOffer: Types.Scalars["Int"]["input"];
}>;

export type UpdateSeatsOnOfferMutation = {
  __typename?: "Mutation";
  adjustSeatsOnOffer: Array<{
    __typename?: "Ride";
    id: string;
    lastModified?: string | null;
    capacity: {
      __typename?: "Capacity";
      decreaseNotPossibleReasons: Array<Types.CapacityManagementNotPossibleReasons>;
      increaseNotPossibleReasons: Array<Types.CapacityManagementNotPossibleReasons>;
      isCapacityInitiated: boolean;
      seatsOnOfferExpected: number;
      minSeatsOnOffer: number;
      segmentCapacity: Array<{
        __typename?: "SegmentCapacityEntry";
        placeAvailable: number;
        placeBooked: number;
        placeReserved: number;
      }>;
    };
  }>;
};

export type StopSalesMutationVariables = Types.Exact<{
  rides: Array<Types.Scalars["ID"]["input"]> | Types.Scalars["ID"]["input"];
}>;

export type StopSalesMutation = {
  __typename?: "Mutation";
  stopSales: Array<{
    __typename?: "Ride";
    id: string;
    salesStopNotPossibleReasons: Array<Types.CapacityManagementNotPossibleReasons>;
    salesRestartNotPossibleReasons: Array<Types.CapacityManagementNotPossibleReasons>;
    lastModified?: string | null;
    saleStatus: Types.RideSaleStatus;
    capacity: {
      __typename?: "Capacity";
      decreaseNotPossibleReasons: Array<Types.CapacityManagementNotPossibleReasons>;
      increaseNotPossibleReasons: Array<Types.CapacityManagementNotPossibleReasons>;
      isCapacityInitiated: boolean;
      seatsOnOfferExpected: number;
      minSeatsOnOffer: number;
      segmentCapacity: Array<{
        __typename?: "SegmentCapacityEntry";
        placeAvailable: number;
        placeBooked: number;
        placeReserved: number;
      }>;
    };
  }>;
};

export type RestartSalesMutationVariables = Types.Exact<{
  rides: Array<Types.Scalars["ID"]["input"]> | Types.Scalars["ID"]["input"];
}>;

export type RestartSalesMutation = {
  __typename?: "Mutation";
  restartSales: Array<{
    __typename?: "Ride";
    id: string;
    salesStopNotPossibleReasons: Array<Types.CapacityManagementNotPossibleReasons>;
    salesRestartNotPossibleReasons: Array<Types.CapacityManagementNotPossibleReasons>;
    lastModified?: string | null;
    saleStatus: Types.RideSaleStatus;
    capacity: {
      __typename?: "Capacity";
      decreaseNotPossibleReasons: Array<Types.CapacityManagementNotPossibleReasons>;
      increaseNotPossibleReasons: Array<Types.CapacityManagementNotPossibleReasons>;
      isCapacityInitiated: boolean;
      seatsOnOfferExpected: number;
      minSeatsOnOffer: number;
      segmentCapacity: Array<{
        __typename?: "SegmentCapacityEntry";
        placeAvailable: number;
        placeBooked: number;
        placeReserved: number;
      }>;
    };
  }>;
};

export type CancelRideMutationVariables = Types.Exact<{
  id: Types.Scalars["ID"]["input"];
}>;

export type CancelRideMutation = {
  __typename?: "Mutation";
  cancelRide: {
    __typename?: "Ride";
    id: string;
    rideCancellationAllowed: boolean;
    lastModified?: string | null;
    saleStatus: Types.RideSaleStatus;
    capacity: {
      __typename?: "Capacity";
      decreaseNotPossibleReasons: Array<Types.CapacityManagementNotPossibleReasons>;
      increaseNotPossibleReasons: Array<Types.CapacityManagementNotPossibleReasons>;
      isCapacityInitiated: boolean;
      seatsOnOfferExpected: number;
      minSeatsOnOffer: number;
      segmentCapacity: Array<{
        __typename?: "SegmentCapacityEntry";
        placeAvailable: number;
        placeBooked: number;
        placeReserved: number;
      }>;
    };
  };
};

export const RouteEntryFragmentDoc = gql`
  fragment RouteEntry on RouteEntry {
    station {
      name
      code
    }
    time {
      localDeparture
      localArrival
    }
  }
`;
export const CapacityWithoutSegmentsFragmentDoc = gql`
  fragment CapacityWithoutSegments on Capacity {
    decreaseNotPossibleReasons
    increaseNotPossibleReasons
    isCapacityInitiated
    seatsOnOfferExpected
    minSeatsOnOffer
  }
`;
export const CapacityFragmentDoc = gql`
  fragment Capacity on Capacity {
    ...CapacityWithoutSegments
    segmentCapacity {
      placeAvailable
      placeBooked
      placeReserved
    }
  }
  ${CapacityWithoutSegmentsFragmentDoc}
`;
export const FindRidesDocument = gql`
  query FindRides(
    $start: String!
    $end: String!
    $filter: Filter
    $contractPartnerId: ID!
  ) {
    findRidesByDeparture(
      start: $start
      end: $end
      filter: $filter
      contractPartnerId: $contractPartnerId
    ) {
      id
      status
      line {
        code
      }
      salesStopNotPossibleReasons
      salesRestartNotPossibleReasons
      rideCancellationAllowed
      lastModified
      lastModifiedUsername
      capacity {
        ...CapacityWithoutSegments
      }
      route {
        ...RouteEntry
      }
      cancellationStatus
      cancellationDate
      saleStatus
      numberOfPassengers
    }
  }
  ${CapacityWithoutSegmentsFragmentDoc}
  ${RouteEntryFragmentDoc}
`;

/**
 * __useFindRidesQuery__
 *
 * To run a query within a React component, call `useFindRidesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindRidesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindRidesQuery({
 *   variables: {
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      filter: // value for 'filter'
 *      contractPartnerId: // value for 'contractPartnerId'
 *   },
 * });
 */
export function useFindRidesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FindRidesQuery,
    FindRidesQueryVariables
  > &
    ({ variables: FindRidesQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<FindRidesQuery, FindRidesQueryVariables>(
    FindRidesDocument,
    options
  );
}
export function useFindRidesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FindRidesQuery,
    FindRidesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<FindRidesQuery, FindRidesQueryVariables>(
    FindRidesDocument,
    options
  );
}
export function useFindRidesSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<FindRidesQuery, FindRidesQueryVariables>
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<FindRidesQuery, FindRidesQueryVariables>(
    FindRidesDocument,
    options
  );
}
export type FindRidesQueryHookResult = ReturnType<typeof useFindRidesQuery>;
export type FindRidesLazyQueryHookResult = ReturnType<typeof useFindRidesLazyQuery>;
export type FindRidesSuspenseQueryHookResult = ReturnType<
  typeof useFindRidesSuspenseQuery
>;
export const FindRidesForRideActionsDocument = gql`
  query FindRidesForRideActions(
    $start: String!
    $end: String!
    $filter: Filter
    $contractPartnerId: ID!
  ) {
    findRidesByDeparture(
      start: $start
      end: $end
      filter: $filter
      contractPartnerId: $contractPartnerId
    ) {
      id
      capacity {
        ...CapacityWithoutSegments
      }
      route {
        ...RouteEntry
      }
      salesStopNotPossibleReasons
      salesRestartNotPossibleReasons
    }
  }
  ${CapacityWithoutSegmentsFragmentDoc}
  ${RouteEntryFragmentDoc}
`;

/**
 * __useFindRidesForRideActionsQuery__
 *
 * To run a query within a React component, call `useFindRidesForRideActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindRidesForRideActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindRidesForRideActionsQuery({
 *   variables: {
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      filter: // value for 'filter'
 *      contractPartnerId: // value for 'contractPartnerId'
 *   },
 * });
 */
export function useFindRidesForRideActionsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FindRidesForRideActionsQuery,
    FindRidesForRideActionsQueryVariables
  > &
    (
      | { variables: FindRidesForRideActionsQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FindRidesForRideActionsQuery,
    FindRidesForRideActionsQueryVariables
  >(FindRidesForRideActionsDocument, options);
}
export function useFindRidesForRideActionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FindRidesForRideActionsQuery,
    FindRidesForRideActionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FindRidesForRideActionsQuery,
    FindRidesForRideActionsQueryVariables
  >(FindRidesForRideActionsDocument, options);
}
export function useFindRidesForRideActionsSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        FindRidesForRideActionsQuery,
        FindRidesForRideActionsQueryVariables
      >
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    FindRidesForRideActionsQuery,
    FindRidesForRideActionsQueryVariables
  >(FindRidesForRideActionsDocument, options);
}
export type FindRidesForRideActionsQueryHookResult = ReturnType<
  typeof useFindRidesForRideActionsQuery
>;
export type FindRidesForRideActionsLazyQueryHookResult = ReturnType<
  typeof useFindRidesForRideActionsLazyQuery
>;
export type FindRidesForRideActionsSuspenseQueryHookResult = ReturnType<
  typeof useFindRidesForRideActionsSuspenseQuery
>;
export const FindRidesForRideTableHeaderDocument = gql`
  query FindRidesForRideTableHeader(
    $start: String!
    $end: String!
    $filter: Filter
    $contractPartnerId: ID!
  ) {
    findRidesByDeparture(
      start: $start
      end: $end
      filter: $filter
      contractPartnerId: $contractPartnerId
    ) {
      id
    }
  }
`;

/**
 * __useFindRidesForRideTableHeaderQuery__
 *
 * To run a query within a React component, call `useFindRidesForRideTableHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindRidesForRideTableHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindRidesForRideTableHeaderQuery({
 *   variables: {
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      filter: // value for 'filter'
 *      contractPartnerId: // value for 'contractPartnerId'
 *   },
 * });
 */
export function useFindRidesForRideTableHeaderQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FindRidesForRideTableHeaderQuery,
    FindRidesForRideTableHeaderQueryVariables
  > &
    (
      | { variables: FindRidesForRideTableHeaderQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FindRidesForRideTableHeaderQuery,
    FindRidesForRideTableHeaderQueryVariables
  >(FindRidesForRideTableHeaderDocument, options);
}
export function useFindRidesForRideTableHeaderLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FindRidesForRideTableHeaderQuery,
    FindRidesForRideTableHeaderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FindRidesForRideTableHeaderQuery,
    FindRidesForRideTableHeaderQueryVariables
  >(FindRidesForRideTableHeaderDocument, options);
}
export function useFindRidesForRideTableHeaderSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        FindRidesForRideTableHeaderQuery,
        FindRidesForRideTableHeaderQueryVariables
      >
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    FindRidesForRideTableHeaderQuery,
    FindRidesForRideTableHeaderQueryVariables
  >(FindRidesForRideTableHeaderDocument, options);
}
export type FindRidesForRideTableHeaderQueryHookResult = ReturnType<
  typeof useFindRidesForRideTableHeaderQuery
>;
export type FindRidesForRideTableHeaderLazyQueryHookResult = ReturnType<
  typeof useFindRidesForRideTableHeaderLazyQuery
>;
export type FindRidesForRideTableHeaderSuspenseQueryHookResult = ReturnType<
  typeof useFindRidesForRideTableHeaderSuspenseQuery
>;
export const LoadCapacityDocument = gql`
  query LoadCapacity($id: ID!) {
    getRide(id: $id) {
      id
      capacity {
        ...Capacity
      }
    }
  }
  ${CapacityFragmentDoc}
`;

/**
 * __useLoadCapacityQuery__
 *
 * To run a query within a React component, call `useLoadCapacityQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadCapacityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadCapacityQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoadCapacityQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    LoadCapacityQuery,
    LoadCapacityQueryVariables
  > &
    ({ variables: LoadCapacityQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<LoadCapacityQuery, LoadCapacityQueryVariables>(
    LoadCapacityDocument,
    options
  );
}
export function useLoadCapacityLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    LoadCapacityQuery,
    LoadCapacityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<LoadCapacityQuery, LoadCapacityQueryVariables>(
    LoadCapacityDocument,
    options
  );
}
export function useLoadCapacitySuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        LoadCapacityQuery,
        LoadCapacityQueryVariables
      >
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<LoadCapacityQuery, LoadCapacityQueryVariables>(
    LoadCapacityDocument,
    options
  );
}
export type LoadCapacityQueryHookResult = ReturnType<typeof useLoadCapacityQuery>;
export type LoadCapacityLazyQueryHookResult = ReturnType<typeof useLoadCapacityLazyQuery>;
export type LoadCapacitySuspenseQueryHookResult = ReturnType<
  typeof useLoadCapacitySuspenseQuery
>;
export const UpdateSeatsOnOfferDocument = gql`
  mutation UpdateSeatsOnOffer($rides: [ID!]!, $expectedSeatsOnOffer: Int!) {
    adjustSeatsOnOffer(rides: $rides, expectedSeatsOnOffer: $expectedSeatsOnOffer) {
      id
      lastModified
      capacity {
        ...Capacity
      }
    }
  }
  ${CapacityFragmentDoc}
`;

/**
 * __useUpdateSeatsOnOfferMutation__
 *
 * To run a mutation, you first call `useUpdateSeatsOnOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSeatsOnOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSeatsOnOfferMutation, { data, loading, error }] = useUpdateSeatsOnOfferMutation({
 *   variables: {
 *      rides: // value for 'rides'
 *      expectedSeatsOnOffer: // value for 'expectedSeatsOnOffer'
 *   },
 * });
 */
export function useUpdateSeatsOnOfferMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateSeatsOnOfferMutation,
    UpdateSeatsOnOfferMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateSeatsOnOfferMutation,
    UpdateSeatsOnOfferMutationVariables
  >(UpdateSeatsOnOfferDocument, options);
}
export type UpdateSeatsOnOfferMutationHookResult = ReturnType<
  typeof useUpdateSeatsOnOfferMutation
>;
export const StopSalesDocument = gql`
  mutation StopSales($rides: [ID!]!) {
    stopSales(rides: $rides) {
      id
      salesStopNotPossibleReasons
      salesRestartNotPossibleReasons
      lastModified
      capacity {
        ...Capacity
      }
      saleStatus
    }
  }
  ${CapacityFragmentDoc}
`;

/**
 * __useStopSalesMutation__
 *
 * To run a mutation, you first call `useStopSalesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStopSalesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stopSalesMutation, { data, loading, error }] = useStopSalesMutation({
 *   variables: {
 *      rides: // value for 'rides'
 *   },
 * });
 */
export function useStopSalesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    StopSalesMutation,
    StopSalesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<StopSalesMutation, StopSalesMutationVariables>(
    StopSalesDocument,
    options
  );
}
export type StopSalesMutationHookResult = ReturnType<typeof useStopSalesMutation>;
export const RestartSalesDocument = gql`
  mutation RestartSales($rides: [ID!]!) {
    restartSales(rides: $rides) {
      id
      salesStopNotPossibleReasons
      salesRestartNotPossibleReasons
      lastModified
      capacity {
        ...Capacity
      }
      saleStatus
    }
  }
  ${CapacityFragmentDoc}
`;

/**
 * __useRestartSalesMutation__
 *
 * To run a mutation, you first call `useRestartSalesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestartSalesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restartSalesMutation, { data, loading, error }] = useRestartSalesMutation({
 *   variables: {
 *      rides: // value for 'rides'
 *   },
 * });
 */
export function useRestartSalesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RestartSalesMutation,
    RestartSalesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    RestartSalesMutation,
    RestartSalesMutationVariables
  >(RestartSalesDocument, options);
}
export type RestartSalesMutationHookResult = ReturnType<typeof useRestartSalesMutation>;
export const CancelRideDocument = gql`
  mutation CancelRide($id: ID!) {
    cancelRide(id: $id) {
      id
      rideCancellationAllowed
      lastModified
      capacity {
        ...Capacity
      }
      saleStatus
    }
  }
  ${CapacityFragmentDoc}
`;

/**
 * __useCancelRideMutation__
 *
 * To run a mutation, you first call `useCancelRideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelRideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelRideMutation, { data, loading, error }] = useCancelRideMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelRideMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CancelRideMutation,
    CancelRideMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<CancelRideMutation, CancelRideMutationVariables>(
    CancelRideDocument,
    options
  );
}
export type CancelRideMutationHookResult = ReturnType<typeof useCancelRideMutation>;
