import { IconComponent, Icon } from "@flixbus/honeycomb-icons-react";
import { Button, Popup, PopupSection } from "@flixbus/honeycomb-react";
import cx from "classnames";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import * as css from "./ConfirmationPopup.scss";
import POPUP_PORTAL_ID from "./PopupPortalId";

export type ConfirmationPopupProps = {
  active: boolean;
  titleIcon?: IconComponent;
  titleText: string;
  confirmButtonText?: string;
  confirmButtonDisabled?: boolean;
  submitLoading?: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  isWide?: boolean;
  hasReducedPaddingOnSmallScreen?: boolean;
  isLeft?: boolean;
  allowOverflow?: boolean;
};

const ConfirmationPopup: React.FC<ConfirmationPopupProps> = ({
  active,
  titleIcon,
  titleText,
  children,
  confirmButtonText,
  confirmButtonDisabled = false,
  submitLoading = false,
  onConfirm,
  onCancel,
  isWide = true,
  hasReducedPaddingOnSmallScreen,
  isLeft,
  allowOverflow,
}) => {
  return (
    <Popup
      portalId={POPUP_PORTAL_ID}
      active={active}
      onOverlayClick={onCancel}
      extraClasses={cx(
        isLeft && css.contentLeft,
        allowOverflow && css.allowOverflow,
        isWide && css.isWide,
        hasReducedPaddingOnSmallScreen && css.hasReducedPaddingOnSmallScreen
      )}
    >
      <PopupSection type="title">
        {titleIcon && (
          <div className={css.titleIcon}>
            <Icon InlineIcon={titleIcon} size={8} />
          </div>
        )}
        <div>{titleText}</div>
      </PopupSection>
      <PopupSection type="content" extraClasses={css.content}>
        {children}
      </PopupSection>
      <PopupSection type="actions">
        <Button data-id="cancel-button" appearance="secondary" onClick={onCancel}>
          <FormattedMessage id="general.cancel" />
        </Button>
        <Button
          id="confirmation-popup-confirm-button"
          data-id="confirm-button"
          appearance="primary"
          loading={submitLoading}
          onClick={onConfirm}
          disabled={confirmButtonDisabled}
        >
          {confirmButtonText || <FormattedMessage id="general.confirm" />}
        </Button>
      </PopupSection>
    </Popup>
  );
};

export default ConfirmationPopup;
