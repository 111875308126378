@use "variables" as *;

.boxWrapper {
  padding-top: $spacing-4;
  margin-right: $spacing-2;
}

.box {
  display: inline-flex;
  align-items: center;
  padding: $spacing-2;
  margin-bottom: 0;
}

.infoIcon {
  margin-right: $spacing-2;
}

.infoIconError {
  color: $danger-color;
}

.infoText {
  font-weight: $font-weight-bold;
}

.closeIcon {
  margin-left: $spacing-2;
  cursor: pointer;
}
