@use "variables" as *;

.departureConnection {
  div {
    color: $grayscale-50-color !important;
  }

  [class^="hcr-connection__item"]::after {
    width: 1px;
    height: 100%;
    margin-top: 5px;
    background: none;
    border-right: 2px dashed $grayscale-50-color;
    white-space: normal;
  }

  [class^="hcr-connection__item"]::before {
    border-style: dashed !important;
    border-color: $grayscale-50-color !important;
    background: none !important;
  }
}
