@use "variables" as *;

.spinnerWrapper {
  display: flex;
  justify-content: center;
  padding-top: 60px;
}

.embeddedReport {
  background: white;
  width: 99%;
  max-width: 1920px;
  margin: -0.5%;
  position: absolute;
  left: 0;
  right: 0;
  overflow: hidden;

  @include full-height-minus-header;

  @include on-bp(md) {
    left: $spacing-2;
  }

  iframe {
    border: none;
  }
}
