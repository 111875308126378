@use "variables" as *;

.wrapper {
  margin-top: $spacing-2;

  @include on-bp(sm) {
    display: inline-block;
  }

  @include on-bp(lg) {
    padding-left: $spacing-4;
    padding-left: $spacing-2;
    margin-top: 0;
  }

  @include on-bp(xl) {
    top: -6px;
  }
}

.button {
  @include on-bp(sm) {
    padding: $spacing-1 $spacing-4;
  }

  @include on-bp(lg) {
    padding: unset;
    width: $input-height-desktop;
    height: $input-height-desktop;
    min-width: $input-height-desktop;
    min-height: $input-height-desktop;
  }
}

.desktopIcon {
  fill: $content-secondary-color !important;
}
