@use "../../helpers/tableStyles/TableStyles.scss";

@use "variables" as *;

.wrapper {
  @include on-bp(xl) {
    height: TableStyles.$table-body-height;
    padding: $spacing-2;
  }
}

.skeleton {
  height: 272px;
  width: 100%;
  margin-bottom: $spacing-2;
  @include on-bp(xl) {
    height: 76px;
  }
}
