@use "variables" as *;

.container {
  display: flex;
  column-gap: $spacing-2;
  padding-left: $spacing-8;
}

.wrapper {
  display: flex;
  align-items: flex-start;
  margin-top: $spacing-1;
}

.legendColor {
  width: 15px;
  height: 15px;
  margin-right: $spacing-1;
  margin-top: calc(#{$spacing-1} / 2);
}

.legendColorRed {
  background: #ff5700;
}

.legendColorGreen {
  background: #73d700;
}

.legendColorBlue {
  background: rgba(0, 181, 226, 0.4);
}

.legendColorLightBlue {
  background: rgba(165, 226, 243, 0.5);
}

.legendColorGray {
  background: rgba(136, 136, 136, 0.4);
}
