import { List, Quantity, Tooltip } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { CapacityManagementNotPossibleReasons } from "../../../../shared/types/schema";
import * as css from "./CapacityInput.scss";

export type CapacityInputProps = {
  capacityOnOffer: number;
  onCapacityChanged: (desiredCapacity: number) => void;
  minCapacity?: number;
  decreaseNotPossibleReasons?: Array<CapacityManagementNotPossibleReasons>;
  increaseNotPossibleReasons?: Array<CapacityManagementNotPossibleReasons>;
  hasError?: boolean;
};

const calculateDecreaseNotPossibleReasons = (
  decreaseNotPossibleReasons: Array<CapacityManagementNotPossibleReasons>,
  isOfferAtMin: boolean
) => {
  const newDecreaseNotPossibleReasons: CapacityManagementNotPossibleReasons[] =
    decreaseNotPossibleReasons.filter(
      (reason) =>
        reason !== CapacityManagementNotPossibleReasons.CapacityIsBelowMinSeatsOnOffer
    );

  if (newDecreaseNotPossibleReasons.length === 0 && isOfferAtMin) {
    newDecreaseNotPossibleReasons.push(
      CapacityManagementNotPossibleReasons.CapacityIsBelowMinSeatsOnOffer
    );
  }

  return newDecreaseNotPossibleReasons;
};

const CapacityInput: React.FC<CapacityInputProps> = ({
  capacityOnOffer,
  onCapacityChanged,
  minCapacity = 0,
  increaseNotPossibleReasons = [],
  decreaseNotPossibleReasons = [],
  hasError = false,
}) => {
  const { formatMessage } = useIntl();

  const QUANTITY_STEP: number = 1;

  const handleValueChanged = (newValue: number) => {
    onCapacityChanged(newValue);
  };

  const isOfferAtMin = capacityOnOffer <= minCapacity;

  const calculatedDecreaseNotPossibleReasons = calculateDecreaseNotPossibleReasons(
    decreaseNotPossibleReasons,
    isOfferAtMin
  );

  const decreaseDisabled = calculatedDecreaseNotPossibleReasons.length > 0;
  const increaseDisabled = increaseNotPossibleReasons.length > 0;

  return (
    <div data-id="tooltip-wrapper" className={css.tooltipWrapper}>
      {(increaseDisabled || decreaseDisabled) && (
        <Tooltip
          id="capacity-input-tooltip"
          position="bottom"
          openOnHover
          content={
            <List extraClasses={css.tooltipContent}>
              {Array.from(
                new Set([
                  ...calculatedDecreaseNotPossibleReasons,
                  ...increaseNotPossibleReasons,
                ])
              ).map((reason) => (
                <li key={reason}>
                  <FormattedMessage
                    id={`ride.CapacityManagementNotPossibleReasons.${reason}`}
                  />
                </li>
              ))}
            </List>
          }
          className={css.tooltipContainer}
        >
          <div className={css.tooltipTarget} />
        </Tooltip>
      )}

      <Quantity
        id="capcity-input"
        label={formatMessage({ id: "ride.seatsOnOffer" })}
        extraClasses={css.quantity}
        value={capacityOnOffer}
        onChange={(e) =>
          handleValueChanged(parseInt((e.target as HTMLInputElement).value, 10))
        }
        onBlur={(e) => {
          if ((e.target as HTMLInputElement).value === "0") {
            handleValueChanged(0);
          }
        }}
        minusBtnProps={{
          disabled: decreaseDisabled || isOfferAtMin,
          onClick: () => handleValueChanged(capacityOnOffer - QUANTITY_STEP),
          title: formatMessage({ id: "ride.seatsOnOffer.decrease" }),
          "aria-label": formatMessage({ id: "ride.seatsOnOffer.decrease" }),
        }}
        plusBtnProps={{
          disabled: increaseDisabled,
          onClick: () => handleValueChanged(capacityOnOffer + QUANTITY_STEP),
          title: formatMessage({ id: "ride.seatsOnOffer.increase" }),
          "aria-label": formatMessage({ id: "ride.seatsOnOffer.increase" }),
        }}
        disabled={increaseDisabled && decreaseDisabled}
        error={hasError}
        min={0}
        step={QUANTITY_STEP}
        info={formatMessage(
          { id: "ride.seatsOnOffer.minimumSeats" },
          { minSeats: minCapacity }
        )}
      />
    </div>
  );
};

export default CapacityInput;
