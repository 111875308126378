@use "variables" as *;

.button {
  width: 100%;
  color: $danger-color;
  border-color: $danger-color;

  svg {
    fill: $danger-color;
  }
}
