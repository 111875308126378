@use "variables" as *;

.wrapper {
  position: relative;
  width: 100%;
  display: inline-grid;
  column-gap: $spacing-2;
  grid-template-columns: auto auto 1fr;
  align-items: center;

  > div:nth-child(3) {
    justify-self: end;
  }
}
