@use "variables" as *;

.wrapper {
  height: 45px;
  @include on-bp(xl) {
    height: 100%;
  }
}
.labelSkeleton {
  margin-top: $spacing-half;
  margin-bottom: 8px;
  height: 16px;
  display: none;
  @include on-bp(xl) {
    display: block;
  }
}

.inputSkeleton {
  height: $input-height-mobile;
  margin-bottom: 0;
  @include on-bp(xl) {
    height: $input-height-desktop;
  }
}

.labelWrapper {
  background: $bg-primary-color;
  border-radius: $border-radius;
  font-size: $font-size-small;
  position: relative;
  top: -11px;
  z-index: 1;
  display: inline;
  line-height: 1em;
  padding: 1px $spacing-1;
  left: $spacing-1;

  @include on-bp(xl) {
    background: none;
    font-size: inherit;
    position: static;
    padding-bottom: $spacing-half;
  }
}

.label {
  color: $content-secondary-color;
}

.disabled {
  opacity: 0.5;
}
