@use "variables" as *;
@use "../../helpers/bookingListStyles/BookingListStyles.scss";

p {
  margin: 0;
}

.bookingListBody {
  max-height: calc(100vh - 390px);
  overflow-y: scroll;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  width: fit-content;
  overflow-x: hidden;

  @include on-bp(md) {
    max-height: calc(100vh - 405px);
  }
  @include on-bp(xl) {
    max-height: calc(100vh - 379px);
  }
}

.bookingListBodyPlaceholder {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.infoWrapper {
  width: 80%;
  text-align: center;
}

.image {
  margin-bottom: $spacing-4;
}

.bookingListItem {
  @include BookingListStyles.bookingListRow;
  min-height: 60px;
  background-color: $bg-primary-color;
  &:nth-child(odd) {
    background-color: $grayscale-10-color;
  }

  &:last-child {
    border-bottom: none;
  }
}

.cell {
  @include BookingListStyles.bookingListCell;
  height: 100%;
}

.connectionCell {
  margin: auto 0;
}
