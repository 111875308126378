@use "../../helpers/bookingListStyles/BookingListStyles.scss";
@use "variables" as *;

.wrapper {
  @include BookingListStyles.contactWrapper;
}

.icon {
  @include BookingListStyles.contactIcon;
}

.phone {
  color: $content-primary-color;
}
