@use "../../helpers/bookingListStyles/BookingListStyles.scss";

@use "variables" as *;

.wrapper {
  @include BookingListStyles.contactWrapper;
}

.icon {
  @include BookingListStyles.contactIcon;
}

.email {
  flex: 1;
  word-break: break-all;
  line-height: normal;
  color: $content-primary-color;

  @include on-bp(md) {
    display: inline-block;
    width: calc(BookingListStyles.$contacts-width - 60px);

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
