@use "variables" as *;

.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(auto-fill, 1fr);
  row-gap: $spacing-6;
  column-gap: $spacing-3;
  margin-top: $spacing-6;
  @include on-bp(md) {
    grid-template-columns: 1fr 1fr;
  }
}

.dayPicker,
.rideFilterSelect,
.completedRideFilter {
  text-align: left;
}
