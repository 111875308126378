import styles from '../../node_modules/@flixbus/honeycomb/dist/scss/helpers/_a11y.scss.js';
import HoneyBem from '../../packages/honey-bem/honey-bem.js';
import { appendHelpersStyles } from '../helpers-utils.js';

var srOnlyBem = HoneyBem.init('sr-only', styles);
var srOnlyFocusableBem = HoneyBem.init('sr-only-focusable', styles);
function a11yHelpers() {
  appendHelpersStyles('A11y', styles);
  return {
    srOnly: srOnlyBem.b(),
    srOnlyFocusable: srOnlyFocusableBem.b()
  };
}

export { a11yHelpers as default };
