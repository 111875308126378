import { format } from "date-fns";
import isValidDate from "../isValidDate/isValidDate";

export default (date: string, dateFormat: string): string => {
  if (!isValidDate(date)) {
    return date;
  }

  return format(new Date(date), dateFormat);
};
