import {
  Icon,
  IconDocumentSolid,
  IconEditSolid,
  IconForbidden,
  IconPauseSolid,
  IconPlaySolid,
  IconSeatSolid,
} from "@flixbus/honeycomb-icons-react";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownItemComp,
} from "@flixbus/honeycomb-react";
import {
  Feature,
  hasUserPermission,
  mpoPathnames as pathnames,
} from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import useRideFilter, {
  rideFilterVar,
} from "../../../../state/useRideFilter/useRideFilter";
import { FindRidesQuery } from "../../api/operations.generated";
import generateRideFilter from "../../helpers/generateRideFilter/generateRideFilter";
import isStopSalesButtonShown from "../../helpers/isStopSalesButtonShown/isStopSalesButtonShown";
import CapacityChangePopup from "../capacityChangePopup/CapacityChangePopup";
import RideActionPopup, { RideActionType } from "../rideActionPopup/RideActionPopup";
import RideSummary from "../rideSummary/RideSummary";
import * as css from "./RideEditButton.scss";

export type RideEditButtonProps = {
  ride: FindRidesQuery["findRidesByDeparture"][number];
  onCapacityChange: (desiredCapacity: number) => void;
  onStopSales: () => void;
  onRestartSales: () => void;
  onRideCancellation: () => void;
};

const RideEditButton: React.FC<RideEditButtonProps> = ({
  ride,
  onCapacityChange,
  onStopSales,
  onRestartSales,
  onRideCancellation,
}) => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const [rideFilter, , setPeriodAndRideFilter] = useRideFilter(rideFilterVar);
  const [rideActionPopupActive, setRideActionPopupActive] = React.useState(false);
  const [popupType, setPopupType] = React.useState<RideActionType>(
    RideActionType.STOP_SALES
  );
  const [capacityChangePopupActive, setCapacityChangePopupActive] = React.useState(false);

  const onConfirm = () => {
    switch (popupType) {
      case RideActionType.STOP_SALES:
        onStopSales();
        break;
      case RideActionType.RESTART_SALES:
        onRestartSales();
        break;
      case RideActionType.CANCELLATION:
        onRideCancellation();
        break;
      default:
        Error("Unexpected ride action triggered");
        break;
    }
    setRideActionPopupActive(false);
  };

  const links: Array<DropdownItemComp> = [];

  if (hasUserPermission(Feature.EDIT_CAPACITY_MANAGER_CAPACITY)) {
    links.push(
      <DropdownItem
        key="change-seats-on-offer"
        InlineIcon={IconSeatSolid}
        Elem="button"
        disabled={
          ride.capacity.increaseNotPossibleReasons.length > 0 &&
          ride.capacity.decreaseNotPossibleReasons.length > 0
        }
        onClick={() => setCapacityChangePopupActive(true)}
      >
        {formatMessage({ id: "ride.batchUpdate.dropdown.item.adjustSeatsOnOffer" })}
      </DropdownItem>
    );
  }

  links.push(
    <DropdownItem
      key="booking-reports"
      InlineIcon={IconDocumentSolid}
      Elem="button"
      onClick={() => {
        setPeriodAndRideFilter(
          generateRideFilter(
            rideFilter,
            ride.route[0].time.localDeparture!!,
            ride.line.code,
            ride.route[0].station.name
          )
        );
        navigate(pathnames.bookingInfo);
      }}
    >
      {formatMessage({ id: "navigation.bookingInfo.button" })}
    </DropdownItem>
  );

  if (hasUserPermission(Feature.EDIT_CAPACITY_MANAGER_STOP_OR_RESTART_SALES)) {
    if (isStopSalesButtonShown(ride.salesStopNotPossibleReasons)) {
      links.push(
        <DropdownItem
          key="stop-sales"
          InlineIcon={IconPauseSolid}
          Elem="button"
          disabled={ride.salesStopNotPossibleReasons.length > 0}
          onClick={() => {
            setPopupType(RideActionType.STOP_SALES);
            setRideActionPopupActive(true);
          }}
        >
          {formatMessage({ id: "ride.stopSales.button" })}
        </DropdownItem>
      );
    } else {
      links.push(
        <DropdownItem
          key="restart-sales"
          InlineIcon={IconPlaySolid}
          Elem="button"
          disabled={ride.salesRestartNotPossibleReasons.length > 0}
          onClick={() => {
            setPopupType(RideActionType.RESTART_SALES);
            setRideActionPopupActive(true);
          }}
        >
          {formatMessage({ id: "ride.restartSale.button" })}
        </DropdownItem>
      );
    }
  }

  if (hasUserPermission(Feature.EDIT_CAPACITY_MANAGER_CANCEL_RIDE)) {
    links.push(
      <DropdownItem
        key="cancel-ride"
        InlineIcon={IconForbidden}
        Elem="button"
        disabled={!ride.rideCancellationAllowed}
        onClick={() => {
          setPopupType(RideActionType.CANCELLATION);
          setRideActionPopupActive(true);
        }}
      >
        {formatMessage({ id: "ride.rideCancellation.button" })}
      </DropdownItem>
    );
  }

  return (
    <>
      <Dropdown links={links} extraClasses={css.dropdown} xPosition="left">
        <Button display="square">
          <Icon InlineIcon={IconEditSolid} />
        </Button>
      </Dropdown>
      {rideActionPopupActive && (
        <RideActionPopup
          type={popupType}
          onCancel={() => setRideActionPopupActive(false)}
          onConfirm={onConfirm}
        >
          <RideSummary route={ride.route} numberOfPassengers={ride.numberOfPassengers} />
        </RideActionPopup>
      )}
      {capacityChangePopupActive && (
        <CapacityChangePopup
          capacity={ride.capacity}
          onConfirm={(desiredCapacity) => {
            onCapacityChange(desiredCapacity);
            setCapacityChangePopupActive(false);
          }}
          onCancel={() => setCapacityChangePopupActive(false)}
        >
          <RideSummary route={ride.route} numberOfPassengers={ride.numberOfPassengers} />
        </CapacityChangePopup>
      )}
    </>
  );
};

export default RideEditButton;
