@use "variables" as *;

.label {
  color: $content-secondary-color;
  font-size: $font-size-small;
}

.route {
  // To compensate for the connections margin
  margin-bottom: calc(-1 * $spacing-1);
}

.paxCount {
  display: inline;
}
