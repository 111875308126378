@use "variables" as *;
@use "../../../../shared/styles/dropdown.scss";

.dropdown {
  > button {
    color: $ui-primary-color !important;
  }

  @include dropdown.dropdown;
}
