@use "variables" as *;

.divider {
  margin-top: $spacing-2;
  margin-bottom: $spacing-2;
}

.summaryWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: $spacing-2;
  row-gap: $spacing-2;

  @include on-bp(md) {
    flex-direction: row;
    justify-content: space-between;
  }

  > div {
    text-align: left;
  }
}
