// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// This file was automatically generated using npm run codegen
// If you want to alter this file please do so via the above mentioned command
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
import * as Types from "../../shared/types/schema";

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
const defaultOptions = {} as const;
export type GetPowerBiEmbeddedReportQueryVariables = Types.Exact<{
  reportUuid: Types.Scalars["ID"]["input"];
  contractPartnerId: Types.Scalars["ID"]["input"];
}>;

export type GetPowerBiEmbeddedReportQuery = {
  __typename?: "Query";
  getPowerBiEmbeddedReport: {
    __typename?: "PowerBiDetailsResponse";
    embedUrl: string;
    embedToken: string;
    tokenExpiry: string;
  };
};

export const GetPowerBiEmbeddedReportDocument = gql`
  query GetPowerBiEmbeddedReport($reportUuid: ID!, $contractPartnerId: ID!) {
    getPowerBiEmbeddedReport(
      reportUuid: $reportUuid
      contractPartnerUuid: $contractPartnerId
    ) {
      embedUrl
      embedToken
      tokenExpiry
    }
  }
`;

/**
 * __useGetPowerBiEmbeddedReportQuery__
 *
 * To run a query within a React component, call `useGetPowerBiEmbeddedReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPowerBiEmbeddedReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPowerBiEmbeddedReportQuery({
 *   variables: {
 *      reportUuid: // value for 'reportUuid'
 *      contractPartnerId: // value for 'contractPartnerId'
 *   },
 * });
 */
export function useGetPowerBiEmbeddedReportQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetPowerBiEmbeddedReportQuery,
    GetPowerBiEmbeddedReportQueryVariables
  > &
    (
      | { variables: GetPowerBiEmbeddedReportQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetPowerBiEmbeddedReportQuery,
    GetPowerBiEmbeddedReportQueryVariables
  >(GetPowerBiEmbeddedReportDocument, options);
}
export function useGetPowerBiEmbeddedReportLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetPowerBiEmbeddedReportQuery,
    GetPowerBiEmbeddedReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetPowerBiEmbeddedReportQuery,
    GetPowerBiEmbeddedReportQueryVariables
  >(GetPowerBiEmbeddedReportDocument, options);
}
export function useGetPowerBiEmbeddedReportSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        GetPowerBiEmbeddedReportQuery,
        GetPowerBiEmbeddedReportQueryVariables
      >
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    GetPowerBiEmbeddedReportQuery,
    GetPowerBiEmbeddedReportQueryVariables
  >(GetPowerBiEmbeddedReportDocument, options);
}
export type GetPowerBiEmbeddedReportQueryHookResult = ReturnType<
  typeof useGetPowerBiEmbeddedReportQuery
>;
export type GetPowerBiEmbeddedReportLazyQueryHookResult = ReturnType<
  typeof useGetPowerBiEmbeddedReportLazyQuery
>;
export type GetPowerBiEmbeddedReportSuspenseQueryHookResult = ReturnType<
  typeof useGetPowerBiEmbeddedReportSuspenseQuery
>;
