// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// This file was automatically generated using npm run codegen
// If you want to alter this file please do so via the above mentioned command
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
import * as Types from "../../shared/types/schema";

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
const defaultOptions = {} as const;
export type FindPartnerBookingInfoConfigQueryVariables = Types.Exact<{
  contractPartnerId: Types.Scalars["ID"]["input"];
}>;

export type FindPartnerBookingInfoConfigQuery = {
  __typename?: "Query";
  findPartner: {
    __typename?: "Partner";
    concessionOwnerId: string;
    contractPartnerId: string;
    bookingInformationConfiguration: {
      __typename?: "BookingInformationConfiguration";
      bookingInformation: Array<Types.BookingInformationField>;
      tripInformation: Array<Types.TripInformationField>;
      passengerInformation: Array<Types.PassengerInformationField>;
    };
  };
};

export type FindBookingsQueryVariables = Types.Exact<{
  start: Types.Scalars["String"]["input"];
  end: Types.Scalars["String"]["input"];
  filter?: Types.InputMaybe<Types.Filter>;
  contractPartnerId: Types.Scalars["ID"]["input"];
}>;

export type FindBookingsQuery = {
  __typename?: "Query";
  findBookings: Array<{
    __typename?: "BookingInformationResponse";
    orderId: string;
    orderReference?: string | null;
    lineCode: string;
    departureTime: string;
    arrivalTime: string;
    departureStation: string;
    arrivalStation: string;
    numberOfPassengers: number;
    orderStatus: Types.OrderStatus;
    contactEmail?: string | null;
    interconnection: boolean;
    ride: {
      __typename?: "Ride";
      id: string;
      localDeparture: string;
      departureStation: { __typename?: "Station"; name: string };
    };
    passengers: Array<{
      __typename?: "Passenger";
      firstName: string;
      lastName: string;
      phone?: string | null;
    }>;
  }>;
};

export const FindPartnerBookingInfoConfigDocument = gql`
  query FindPartnerBookingInfoConfig($contractPartnerId: ID!) {
    findPartner(contractPartnerId: $contractPartnerId) {
      concessionOwnerId
      contractPartnerId
      bookingInformationConfiguration {
        bookingInformation
        tripInformation
        passengerInformation
      }
    }
  }
`;

/**
 * __useFindPartnerBookingInfoConfigQuery__
 *
 * To run a query within a React component, call `useFindPartnerBookingInfoConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindPartnerBookingInfoConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindPartnerBookingInfoConfigQuery({
 *   variables: {
 *      contractPartnerId: // value for 'contractPartnerId'
 *   },
 * });
 */
export function useFindPartnerBookingInfoConfigQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FindPartnerBookingInfoConfigQuery,
    FindPartnerBookingInfoConfigQueryVariables
  > &
    (
      | { variables: FindPartnerBookingInfoConfigQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FindPartnerBookingInfoConfigQuery,
    FindPartnerBookingInfoConfigQueryVariables
  >(FindPartnerBookingInfoConfigDocument, options);
}
export function useFindPartnerBookingInfoConfigLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FindPartnerBookingInfoConfigQuery,
    FindPartnerBookingInfoConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FindPartnerBookingInfoConfigQuery,
    FindPartnerBookingInfoConfigQueryVariables
  >(FindPartnerBookingInfoConfigDocument, options);
}
export function useFindPartnerBookingInfoConfigSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        FindPartnerBookingInfoConfigQuery,
        FindPartnerBookingInfoConfigQueryVariables
      >
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    FindPartnerBookingInfoConfigQuery,
    FindPartnerBookingInfoConfigQueryVariables
  >(FindPartnerBookingInfoConfigDocument, options);
}
export type FindPartnerBookingInfoConfigQueryHookResult = ReturnType<
  typeof useFindPartnerBookingInfoConfigQuery
>;
export type FindPartnerBookingInfoConfigLazyQueryHookResult = ReturnType<
  typeof useFindPartnerBookingInfoConfigLazyQuery
>;
export type FindPartnerBookingInfoConfigSuspenseQueryHookResult = ReturnType<
  typeof useFindPartnerBookingInfoConfigSuspenseQuery
>;
export const FindBookingsDocument = gql`
  query FindBookings(
    $start: String!
    $end: String!
    $filter: Filter
    $contractPartnerId: ID!
  ) {
    findBookings(
      start: $start
      end: $end
      filter: $filter
      contractPartnerId: $contractPartnerId
    ) {
      ride {
        id
        departureStation {
          name
        }
        localDeparture
      }
      orderId
      orderReference
      lineCode
      departureTime
      arrivalTime
      departureStation
      arrivalStation
      numberOfPassengers
      orderStatus
      contactEmail
      passengers {
        firstName
        lastName
        phone
      }
      interconnection
    }
  }
`;

/**
 * __useFindBookingsQuery__
 *
 * To run a query within a React component, call `useFindBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindBookingsQuery({
 *   variables: {
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      filter: // value for 'filter'
 *      contractPartnerId: // value for 'contractPartnerId'
 *   },
 * });
 */
export function useFindBookingsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FindBookingsQuery,
    FindBookingsQueryVariables
  > &
    ({ variables: FindBookingsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<FindBookingsQuery, FindBookingsQueryVariables>(
    FindBookingsDocument,
    options
  );
}
export function useFindBookingsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FindBookingsQuery,
    FindBookingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<FindBookingsQuery, FindBookingsQueryVariables>(
    FindBookingsDocument,
    options
  );
}
export function useFindBookingsSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        FindBookingsQuery,
        FindBookingsQueryVariables
      >
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<FindBookingsQuery, FindBookingsQueryVariables>(
    FindBookingsDocument,
    options
  );
}
export type FindBookingsQueryHookResult = ReturnType<typeof useFindBookingsQuery>;
export type FindBookingsLazyQueryHookResult = ReturnType<typeof useFindBookingsLazyQuery>;
export type FindBookingsSuspenseQueryHookResult = ReturnType<
  typeof useFindBookingsSuspenseQuery
>;
