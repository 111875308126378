import { formatDateStringForApi } from "@flixbus-phx/marketplace-common";
import { RideFilter } from "../../../../state/useRideFilter/useRideFilter";

export default (
  rideFilter: RideFilter,
  dateTime: string,
  lineCode: string,
  departureStation: string
): RideFilter => {
  const date = dateTime.split("T")[0];
  const time = dateTime.split("T")[1];

  return {
    ...rideFilter,
    flixLineCode: lineCode,
    departureStation,
    departureTime: time,
    period: {
      startDate: formatDateStringForApi(date),
      endDate: formatDateStringForApi(date),
    },
  };
};
