import { Icon, IconInfo } from "@flixbus/honeycomb-icons-react";
import { Heading, Tooltip } from "@flixbus/honeycomb-react";
import { Breakpoint, ResponsiveRenderer } from "@flixbus-phx/marketplace-common";
import image from "@flixbus-phx/marketplace-common/assets/illustrations/passenger-sleeping.svg";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { FindBookingsQuery } from "../../api/operations.generated";
import BookingCard from "../bookingCard/BookingCard";
import BookingListBody from "../bookingListBody/BookingListBody";
import * as css from "./BookingList.scss";

type Props = {
  bookings: FindBookingsQuery["findBookings"];
};

const BookingList: React.FC<Props> = ({ bookings }) => {
  return (
    <div className={css.wrapper}>
      {bookings.length > 0 ? (
        <>
          <ResponsiveRenderer hidden={[Breakpoint.XXS, Breakpoint.XS, Breakpoint.SM]}>
            <div className={css.bookingList}>
              <div className={css.bookingListHeader}>
                <div>
                  <FormattedMessage id="general.status" />
                </div>
                <div>
                  <FormattedMessage id="general.line" />
                </div>
                <div>
                  <FormattedMessage id="general.travelDate" />
                </div>
                <div>
                  <FormattedMessage id="general.route" />
                </div>
                <div>
                  <FormattedMessage id="general.orderReference" />
                  <Tooltip
                    id="bookingInfo.orderReferenceHint"
                    position="bottom"
                    content={<FormattedMessage id="bookingInfo.orderReferenceHint" />}
                    extraClasses={css.tooltip}
                  >
                    <Icon InlineIcon={IconInfo} extraClasses={css.icon} />
                  </Tooltip>
                </div>
                <div>
                  <FormattedMessage id="general.oldBookingId" />
                </div>
                <div>
                  <FormattedMessage id="general.passengers" />
                </div>
                <div>
                  <FormattedMessage id="general.contacts" />
                </div>
                <div>
                  <FormattedMessage id="general.interconnection" />
                </div>
              </div>
              <BookingListBody bookings={bookings} />
            </div>
          </ResponsiveRenderer>

          <ResponsiveRenderer hidden={[Breakpoint.MD, Breakpoint.LG, Breakpoint.XL]}>
            {bookings.map((booking) => (
              <BookingCard
                key={`${booking.orderId}-${booking.departureTime}`}
                booking={booking}
              />
            ))}
          </ResponsiveRenderer>
        </>
      ) : (
        <div className={css.noBookingsWrapper}>
          <Heading
            data-id="no-bookings-heading"
            size={2}
            extraClasses={css.noBookingsHeading}
          >
            <FormattedMessage id="bookingInfo.info.noBookings" />
          </Heading>
          <div>
            <img src={image} alt="" height="100%" width="auto" />
          </div>
        </div>
      )}
    </div>
  );
};

export default BookingList;
