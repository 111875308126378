import { Icon, IconPersonSolid } from "@flixbus/honeycomb-icons-react";
import { Button } from "@flixbus/honeycomb-react";
import {
  Breakpoint,
  Feature,
  hasUserPermission,
  mpoPathnames as pathnames,
  useIsInRange,
} from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Ride } from "../../../../shared/types/schema";
import * as css from "./PaxListButton.scss";

type Props = {
  rideId: Ride["id"];
};

const PaxListButton: React.FC<Props> = ({ rideId }) => {
  const isMobile = useIsInRange([
    Breakpoint.XXS,
    Breakpoint.XS,
    Breakpoint.SM,
    Breakpoint.MD,
  ]);

  if (hasUserPermission(Feature.VIEW_PAX_LIST)) {
    return (
      <div className={css.wrapper}>
        <Button
          extraClasses={css.button}
          display={isMobile ? "block" : "square"}
          appearance={isMobile ? "primary" : "secondary"}
          RouterLink={Link}
          to={pathnames.paxList.replace(":rideId", rideId)}
        >
          <Icon
            InlineIcon={IconPersonSolid}
            aria-hidden="true"
            extraClasses={!isMobile ? css.desktopIcon : undefined}
          />
          {isMobile && <FormattedMessage id="checkIn.paxList.headline" />}
        </Button>
      </div>
    );
  }

  return null;
};

export default PaxListButton;
