@use "variables" as *;

.tooltipWrapper {
  position: relative;
  display: flex;
  justify-content: center;
}

.quantity {
  & input {
    &:disabled {
      cursor: not-allowed;
    }
  }
  & button {
    &:disabled {
      cursor: not-allowed;
    }
  }
  & > span {
    text-align: center;
  }
  & > label {
    text-align: left;
  }
}

.tooltipContainer {
  width: 36px;
  height: 36px;
  position: absolute;
  top: 26px;
  left: 104px;
  z-index: 11;
}

.tooltipContent {
  margin-bottom: 0;
  > li {
    color: $grayscale-70-color;
    &::before {
      background: $grayscale-70-color;
    }
  }
}

.tooltipTarget {
  height: 100%;
  width: 100%;
  cursor: not-allowed;
}
