// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// This file was automatically generated using npm run codegen
// If you want to alter this file please do so via the above mentioned command
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
import * as Types from "../../shared/types/schema";

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
const defaultOptions = {} as const;
export type RidePassengerFragment = {
  __typename?: "RidePassenger";
  id: string;
  name: string;
  checkInStatus: Types.CheckInStatus;
  departureStationId: string;
  status: Types.RidePassengerStatus;
};

export type FindRidePaxQueryVariables = Types.Exact<{
  id: Types.Scalars["ID"]["input"];
}>;

export type FindRidePaxQuery = {
  __typename?: "Query";
  getRide: {
    __typename?: "Ride";
    id: string;
    checkInAllowed: boolean;
    route: Array<{
      __typename?: "RouteEntry";
      station: { __typename?: "Station"; id: string; name: string };
    }>;
    ridePassengers: Array<{
      __typename?: "RidePassenger";
      id: string;
      name: string;
      checkInStatus: Types.CheckInStatus;
      departureStationId: string;
      status: Types.RidePassengerStatus;
    }>;
  };
};

export type UpdatePassengerStatusMutationVariables = Types.Exact<{
  ridePassengerId: Types.Scalars["ID"]["input"];
  actionType: Types.PassengerUpdateAction;
}>;

export type UpdatePassengerStatusMutation = {
  __typename?: "Mutation";
  updatePassengerStatus: {
    __typename?: "RidePassenger";
    id: string;
    name: string;
    checkInStatus: Types.CheckInStatus;
    departureStationId: string;
    status: Types.RidePassengerStatus;
  };
};

export type UpdateOrderPassengerStatusMutationVariables = Types.Exact<{
  rideId: Types.Scalars["ID"]["input"];
  orderId: Types.Scalars["ID"]["input"];
}>;

export type UpdateOrderPassengerStatusMutation = {
  __typename?: "Mutation";
  updateOrderPassengerStatus: Array<{
    __typename?: "RidePassenger";
    id: string;
    name: string;
    checkInStatus: Types.CheckInStatus;
    departureStationId: string;
    status: Types.RidePassengerStatus;
  }>;
};

export const RidePassengerFragmentDoc = gql`
  fragment RidePassenger on RidePassenger {
    id
    name
    checkInStatus
    departureStationId
    status
  }
`;
export const FindRidePaxDocument = gql`
  query FindRidePax($id: ID!) {
    getRide(id: $id) {
      id
      checkInAllowed
      route {
        station {
          id
          name
        }
      }
      ridePassengers {
        ...RidePassenger
      }
    }
  }
  ${RidePassengerFragmentDoc}
`;

/**
 * __useFindRidePaxQuery__
 *
 * To run a query within a React component, call `useFindRidePaxQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindRidePaxQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindRidePaxQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFindRidePaxQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FindRidePaxQuery,
    FindRidePaxQueryVariables
  > &
    ({ variables: FindRidePaxQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<FindRidePaxQuery, FindRidePaxQueryVariables>(
    FindRidePaxDocument,
    options
  );
}
export function useFindRidePaxLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FindRidePaxQuery,
    FindRidePaxQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<FindRidePaxQuery, FindRidePaxQueryVariables>(
    FindRidePaxDocument,
    options
  );
}
export function useFindRidePaxSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        FindRidePaxQuery,
        FindRidePaxQueryVariables
      >
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<FindRidePaxQuery, FindRidePaxQueryVariables>(
    FindRidePaxDocument,
    options
  );
}
export type FindRidePaxQueryHookResult = ReturnType<typeof useFindRidePaxQuery>;
export type FindRidePaxLazyQueryHookResult = ReturnType<typeof useFindRidePaxLazyQuery>;
export type FindRidePaxSuspenseQueryHookResult = ReturnType<
  typeof useFindRidePaxSuspenseQuery
>;
export const UpdatePassengerStatusDocument = gql`
  mutation updatePassengerStatus(
    $ridePassengerId: ID!
    $actionType: PassengerUpdateAction!
  ) {
    updatePassengerStatus(ridePassengerId: $ridePassengerId, actionType: $actionType) {
      ...RidePassenger
    }
  }
  ${RidePassengerFragmentDoc}
`;

/**
 * __useUpdatePassengerStatusMutation__
 *
 * To run a mutation, you first call `useUpdatePassengerStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePassengerStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePassengerStatusMutation, { data, loading, error }] = useUpdatePassengerStatusMutation({
 *   variables: {
 *      ridePassengerId: // value for 'ridePassengerId'
 *      actionType: // value for 'actionType'
 *   },
 * });
 */
export function useUpdatePassengerStatusMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdatePassengerStatusMutation,
    UpdatePassengerStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdatePassengerStatusMutation,
    UpdatePassengerStatusMutationVariables
  >(UpdatePassengerStatusDocument, options);
}
export type UpdatePassengerStatusMutationHookResult = ReturnType<
  typeof useUpdatePassengerStatusMutation
>;
export const UpdateOrderPassengerStatusDocument = gql`
  mutation updateOrderPassengerStatus($rideId: ID!, $orderId: ID!) {
    updateOrderPassengerStatus(rideId: $rideId, orderId: $orderId) {
      ...RidePassenger
    }
  }
  ${RidePassengerFragmentDoc}
`;

/**
 * __useUpdateOrderPassengerStatusMutation__
 *
 * To run a mutation, you first call `useUpdateOrderPassengerStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderPassengerStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderPassengerStatusMutation, { data, loading, error }] = useUpdateOrderPassengerStatusMutation({
 *   variables: {
 *      rideId: // value for 'rideId'
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useUpdateOrderPassengerStatusMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateOrderPassengerStatusMutation,
    UpdateOrderPassengerStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateOrderPassengerStatusMutation,
    UpdateOrderPassengerStatusMutationVariables
  >(UpdateOrderPassengerStatusDocument, options);
}
export type UpdateOrderPassengerStatusMutationHookResult = ReturnType<
  typeof useUpdateOrderPassengerStatusMutation
>;
