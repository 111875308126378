@use "variables" as *;

.background {
  position: relative;
  width: calc(100% + 24px);
  left: -12px;
  overflow: unset;
  padding-left: 12px;
  padding-right: 12px;
  background-color: $grayscale-10-color;
  height: 100%;
  min-height: calc(100vh - $filter-height - 1px);

  @include on-bp(md) {
    min-height: calc(100vh - $filter-height-md - 1px);
  }

  @include on-bp(xl) {
    position: absolute;
    left: 0;
    width: 100%;
    height: calc(100vh - $filter-height-xl - 1px);
    overflow: hidden;
  }
}
