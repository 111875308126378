@use "variables" as *;

.spinnerWrapper {
  display: flex;
  justify-content: center;
  padding-top: 60px;
}

.wrapper {
  @include max-content-width;
  padding-top: $spacing-2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include on-bp(md) {
    padding-top: $spacing-3;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.errorWrapper {
  position: relative;
  padding-top: $spacing-3;
}

.heading {
  font-size: 16px;
  line-height: $spacing-4;
  padding-top: 0;
  padding-bottom: $spacing-2;

  @include on-bp(md) {
    font-size: $font-size-h2;
    line-height: $line-height-h2;
    padding-bottom: 0;
  }
}
