@use "variables" as *;
@use "common/config/breakpoints";

.picker {
  ul {
    width: 100%;
    li {
      flex-grow: 1;
      min-width: 40px;

      label {
        justify-content: center;
      }
    }
  }

  @media (max-width: (breakpoints.$breakpoint-sm - 1px)),
    (min-width: (breakpoints.$breakpoint-md)) and (max-width: (breakpoints.$breakpoint-xl - 1px)) {
    ul {
      li label {
        padding: 9px 0 6px 0 !important;
        &::before {
          display: none !important;
        }
      }
    }
  }
}
