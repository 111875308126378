import { Icon, IconCheckmark } from "@flixbus/honeycomb-icons-react";
import { Button } from "@flixbus/honeycomb-react";
import React from "react";
import { FormattedMessage } from "react-intl";
import { CheckInStatus, PassengerUpdateAction } from "../../../../shared/types/schema";
import * as css from "./CheckInButton.scss";

export type CheckInButtonProps = {
  status: CheckInStatus;
  onStatusChange: (updateAction: PassengerUpdateAction) => void;
  isLoading: boolean;
};

const CheckInButton: React.FC<CheckInButtonProps> = ({
  status,
  onStatusChange,
  isLoading,
}) => {
  return (
    <Button
      loading={isLoading}
      aria-label="Check-In"
      appearance={status === CheckInStatus.Checked ? "secondary" : undefined}
      onClick={() =>
        onStatusChange(
          status === CheckInStatus.Checked
            ? PassengerUpdateAction.CheckOut
            : PassengerUpdateAction.CheckIn
        )
      }
    >
      {status === CheckInStatus.Checked ? (
        <>
          <Icon InlineIcon={IconCheckmark} extraClasses={css.checkedIcon} />
          <FormattedMessage id={`checkIn.checkInStatus.${CheckInStatus.Checked}`} />
        </>
      ) : (
        <FormattedMessage id="checkIn.manualCheckIn.unchecked" />
      )}
    </Button>
  );
};

export default CheckInButton;
